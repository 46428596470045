



import { Component, Vue } from "vue-property-decorator";
import PagoStripeModule from "@/store/modules/Stripe/PagoStripe-module";
import { registrostestweb } from "@/shared/dtos/registrostestweb";
import { RouterNames } from "@/router/routernames";
import { UtilsString } from "@/utils/utils-string";
import { registrationService } from "@/shared/services/RegistrationService";

@Component({
  $_veeValidate: { validator: "new" },
})
export default class RegisterFormulario extends Vue {
  public appTitle = process.env.VUE_APP_TITLE;
  public nutricionista: registrostestweb = new registrostestweb();
  public type_pass: string = "password";
  public errores_registro: string = "";
  public licencia = "mensual";

  public created() {
    this.nutricionista.id = -1;
    try {
      if (this.$route.query.lic !== undefined) {
        if (UtilsString.ValueOf(this.$route.query.lic) === "unico") {
          this.licencia = "unico";
        } else {
          this.licencia = "mensual";
        }
      }
    } catch (error) {}
  }

  public showhide() {
    if (this.type_pass === "text") {
      this.type_pass = "password";
    } else {
      this.type_pass = "text";
    }
  }

  public submit() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        registrationService
          .crear_registro(this.nutricionista)
          .then(() => this.creationResult(true))
          .catch((error: any) => {
            let message = "";
            if (error.response !== undefined) {
              if (error.response.data !== undefined) {
                //res.response.data.message
                message = UtilsString.ValueOf(error.response.data.message);
              }
            }
            if (UtilsString.IsNullOrWhiteSpace(message)) {
              message = error.message || error.response.statusText;
            }
            this.creationResult(false, message);
          });
      }
    });
  }

  private async creationResult(loginOk: boolean, err: string = "") {
    if (loginOk) {
      setTimeout(() => this.routeToPayment(), 250);
    } else {
      const ssmMessageService = await import(
        "@/shared/services/message-service"
      );
      if (err === "inicie sesión o restablezca la contraseña") {
        // setTimeout(() => this.routeToPayment(), 250);
        ssmMessageService.ssmMessageService.alertRespose(
          "¡Enhorabuena!",
          "¡Bienvenido de vuelta! Como cliente de My Medical Diet, simplemente sigue adelante para acceder a la aplicación mediante nuestra pasarela de pago.",

          () => {
            setTimeout(() => this.routeToPayment(), 250);
          },
          ssmMessageService.ssmMessageService.TypeSuccess,
          90000
        );
      } else {
        ssmMessageService.ssmMessageService.toast(
          err,
          ssmMessageService.ssmMessageService.TypeError
        );
      }
    }
  }
  private routeToPayment() {
    if (UtilsString.ValueOf(this.licencia) === "unico") {
      PagoStripeModule.GetPagoUnico(this.nutricionista).then((x) => {
        window.open(UtilsString.ValueOf(x));
      });
    } else {
      PagoStripeModule.GetSuscripcion(this.nutricionista).then((x) => {
        window.open(UtilsString.ValueOf(x));
      });
    }
    //this.$router.push({ name: RouterNames.confirm });
  }

  private routeToConfirmEmail() {
    this.$router.push({ name: RouterNames.confirm });
  }

  private GoLogin() {
    this.$router.push({ name: RouterNames.Login });
  }
}
