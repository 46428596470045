import { store } from '@/store/store';
import { registrostestweb } from '@/shared/dtos/registrostestweb';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'PagoStripeModule',
    store,
    dynamic: true
})
class PagoStripeModule extends VuexModule {
    public registro: registrostestweb = new registrostestweb();

    @Action
    public async GetPagoUnico(registrostestweb: registrostestweb) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        return await ssmHttpService.post(API.PagosStripe + '/link-unico/', registrostestweb.toJson(), true, false, true);
    }

    @Action
    public async GetSuscripcion(registrostestweb: registrostestweb) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        return await ssmHttpService.post(API.PagosStripe + '/link-suscripcion/', registrostestweb.toJson(), true, false, true);
    }

    /*
    @Action({ commit: 'onGetregistrostestweb' })
    public async ValidateRegistro(token: string) {
        return await ssmHttpService.post(API.registrostestweb + '/validate/' + token, null, false, false, true);
    }

    @Mutation
    public onGetregistrostestweb(res: registrostestweb) {
        this.registro = new registrostestweb(res);
    }*/

}
export default getModule(PagoStripeModule);