import { API } from '../api';
import { ssmHttpService } from './http-service';
import { registrostestweb } from '@/shared/dtos/registrostestweb';

export default class RegistrationService {
    public crear_registro(registrostestweb: registrostestweb): Promise<boolean> {
        return ssmHttpService.post(API.registrostestweb, registrostestweb.toJson());
    }
}

export const registrationService = new RegistrationService();